import { VideoStatus } from '@/pages/Groups/types'
import { IPagination } from '../types'

export enum GroupType {
  ACTIVE = 2,
  FINISHED = 3,
}

export interface IGroupParams {
  status?: GroupType
}

export interface IGroupsData {
  groups: IGroupData[]
  total: number
}

export interface StaffGroupDetail {
  title: string
  staff: StaffOfGroup[]
}

export enum GroupStatus {
  // NOT_STARTED = 1,
  ACTIVE = 2,
  // ENDED = 3,
}
export interface Category {
  id: number
  hName: string
  displayName: string
  createdAt: Date
}
export interface Role {
  id: number
  name: string
  type: Role
  createdAt: Date
}

export interface Group {
  id: number
  name: string
  staffDetail: StaffGroupDetail[]
  studentsCount: number
  startedDate: Date
  endedDate: Date
  status: GroupStatus
  beginTime: string
  endTime: string
  category?: Category
  scheduleItems: ScheduleItem[]
}

export interface StaffOfGroup {
  id: number
  firstName: string
  lastName: string
  role: Role
  hasPermission?: boolean
}

export interface ScheduleItem {
  id: number
  staff: StaffOfGroup
  daysOfWeek: string[]
  role: Role
  beginTime: string
  endTime: string
}

export interface ISchedules {
  activeScheduleItems: ScheduleItem[]
  othersScheduleItems: ScheduleItem[]
}

export interface IGroupData {
  group: IGroup
  id: number
  hasPermission: boolean
  name?: string
  staff: IGroupStaffs
  scheduleItems: ScheduleItem[]
}

export interface IGroup {
  beginTime: string
  categoryName: string
  activeScheduleItems?: ScheduleItem[]
  category?: Category
  isStudying?: boolean
  endedDate: string
  endTime: string
  id: number
  name: string
  startedDate: string
  status: GroupType
  studentsCount: number
  staffDetail: IGroupStaffDetail[]
}

export interface IGroupStaffDetail {
  title: string
  staff: IGroupStaffs[]
}

export interface IGroupStaffs {
  firstName: string
  lastName: string
  photo: string
  id: number
  middleName?: string
}

export interface IGroupInfoParams extends IPagination {
  id: number
  staffId: number
}

export interface IGroupInfoData {
  groupData: IGroupInfo
  total: number
}
export interface IGroupInfo {
  group: IGroup
  id: number
  lessons: IGroupLesson[]
}

export interface IGroupLesson {
  createdAt: string
  hasRating: boolean
  homework: IGroupLessonHomework
  id: number
  name: string
  videos: IGroupLessonVideo[]
}

export interface IGroupLessonVideo {
  id: number
}

export interface IGroupLessonHomework {
  createdAt: string
  deadline: string
  homeworkInfo: IGroupLessonInfo
  id: number
  studentsCount: number
}

export interface IGroupLessonInfo {
  attachments: IGroupLessonVideoAttachment[]
  desc: string
  createdAt: string
  deadlinePassed: boolean
  deadline: string
  canSubmit?: boolean
  announcedAt?: string
}

export interface IGroupLessonVideoAttachment {
  id?: number
  origName: string
  size: number
  url: string
  file?: File
  progress?: number
  isUploading?: boolean
}

export interface IGroupLessonVideos {
  groupData: IGroupVideoLessons
  total: number
}

export interface IGroupVideoLessons {
  group: IGroup
  id: number
  lessons: IGroupVideoLesson[]
}

export interface IGroupVideoLesson {
  createdAt: string
  hasRating: boolean
  homework: IGroupLessonHomework | null
  deadlinePassed: boolean
  id: number
  name: string
  videos: ILessonVideos[]
  examId?: number
  isAvailableForSubmitHomework?: boolean
}

export interface ILessonVideos {
  createdAt: string
  id: number
  index: number
  lesson: string
  origName: string
  size: number
  status: VideoStatus
  statusHistories: []
  url: string
  isLastVideo: boolean
}

export interface IGroupRatingParams {
  lessonId: number
  rating: number
}

export interface ISuccessResponse {
  success: boolean
}

export interface IHomeworkChatsGet {
  homeworkChatsData: IHomeworkChat
  total: number
}

export interface IHomeworkChat {
  homeworkChats: IHomeworkChatCome[]
  homeworkInfo: IGroupLessonInfo | null
  lesson?: IGroupLesson
}

export interface IUploadHomewok {
  fileName: string
  path: string
  size: number
  progress?: number
}

export type THomeworkAttachments = {
  url: string
  origName: string
  size: number
}

export interface IHomeworkSend {
  attachments: THomeworkAttachments[]
  homeworkId?: number
  message: string
}

export type IHomeworkData = Omit<IHomeworkSend, 'homeworkId'>

export interface IExamInfo {
  id: number
  data: IHomeworkData
}

export interface IHomeworkChatCome {
  attachments: IGroupLessonVideoAttachment[]
  createdAt: string
  id: number
  message: string
  receiver: string
  sender: IHomeworkSender
  status: HomeworkChatsStatus
  updatedAt: string
  score?: number
}

export interface IHomeworkSender {
  firstName: string
  id: number
  lastName: string
  userType: UserTypes
  middleName?: string
}

export enum HomeworkChatsStatus {
  WAITING = 1,
  REJECTED,
  ACCEPTED,
  CHECKING,
  NOT_DONE,
}

export enum UserTypes {
  STAFF = 1,
  STUDENT = 2,
}

export interface GetHomeworkParams {
  groupId: number
  homeworkId: number
}

export interface IGroupLessonParams {
  groupId: number
  staffId: number
}

export enum IGroupChapterDeadline {
  Asc = 'ASC',
  Desc = 'DESC',
}

export interface IGetGroupChapters {
  id: number
  page?: number
  perPage?: number
  homeworkStatus?: HomeworkFilterStatus | string | null
  homeworkDeadline?: IGroupChapterDeadline
  createdAt?: IGroupChapterDeadline
}

export enum HomeworkFilterStatus {
  Waiting = 1,
  Rejected,
  Accepted,
  Checking,
  Uncompleted,
  NotGiven,
}

export type Chapters = {
  id: number
  name: string
  videosCount: number
  homeworkStatus: HomeworkFilterStatus
  createdAt: string
  homeworkDeadline: string
  examId?: number
}

export interface IGroupChapters {
  groupLessonsData: {
    groupLessons: Chapters[]
    groupName: string
  }
}

export type FileInformation = {
  file: File | null
  id: number
  hasError?: boolean
  progress?: number
  isUploading?: boolean
  origName: string
  size?: number
  url: string
  onAbort?: () => void
}

export interface IGroupLessonVideoNameResponse {
  id: number
  origName: string
}

export type IFile = Omit<FileInformation, 'url'>

export type TGetHomeworkFileUrlRes = {
  filename: string
}

export enum IExamStatus {
  Failed = 1,
  Passed,
  Absent,
  FailedExam,
}

export type IExamResult = {
  id: number
  firstName: string
  lastName: string
  middleName: string
  score: number
  status: IExamStatus
  failingReason: string
}

export type LastVideoParamsType = {
  videoId: number
  groupId: number
}
