export const DATE_MASK = '__.__.____'
export const TIME_MASK = '__:__'
export const DATE_TIME_MASK = '__.__.____ __:__'
export const DATE_FORMAT = 'DD.MM.YYYY'
export const TIME_FORMAT = 'HH:mm'
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'
export const REQUEST_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZZ'

export const DEFAULT_LOCALE = 'ru'
export const DEFAULT_TIMEZONE = 'Asia/Tashkent'

export const months = ['Yan', 'Fev', 'Mart', 'Apr', 'May', 'Iyun', 'Iyul', 'Avg', 'Sen', 'Okt', 'Noy', 'Dek']
export const weekDaysShort = ['Ya', 'Du', 'Se', 'Cho', 'Pay', 'Ju', 'Sha']
export const weekDays = ['Yakshanba', 'Dushanba', 'Seshanba', 'Chorshanba', 'Payshanba', 'Juma', 'Shanba']
export const VAPID_KEY = 'BOEo5OmOYvrEFSJJF1Dxd1kXEqUE83AKVnIkoI7WhSsdj8HYkKJ54xn17TzJIyL8PGi7MY7nanfp2IMVK56AO8c'
