import { INetworkConfig, Instance } from '@/api/instance'
import { IResponse } from '@/api/types'
import { IGetCourseRequest } from '@/stores/rating/types'
import { Endpoints } from '../endpoints'
import {
  IRatingCategories,
  IRatingCourses,
  IRatingData,
  IRatingLevelByCategories,
  IRatingParams,
  TGetBranchResponse,
} from './types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class RatingApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getRatingCategories = (): Promise<IResponse<IRatingCategories>> => this.get(Endpoints.Categories)

  getRating = (params: IRatingParams): Promise<IResponse<IRatingData>> => this.get(Endpoints.Rating, { params })

  getLevelByCategory = (): Promise<IResponse<IRatingLevelByCategories>> => this.get(Endpoints.LevelsByCategory)

  getCourses = (params?: IGetCourseRequest): Promise<IResponse<IRatingCourses>> =>
    this.get(Endpoints.Courses, { params })

  getBranches = (): Promise<IResponse<TGetBranchResponse>> => this.get(Endpoints.Offices)
}

export const ratingApi = new RatingApi(config)
