import { NotificationParams } from '@/stores/notifications'
import { Endpoints } from '../endpoints'
import { INetworkConfig, Instance } from '../instance'
import { IResponse } from '../types'
import { Notification, NotificationResponse } from './types'

const config: INetworkConfig = {
  baseURL: `${Endpoints.Base}${Endpoints.Notifications}`,
}

class NotificationsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getNotifications = (params: NotificationParams): Promise<IResponse<NotificationResponse>> =>
    this.get(`${Endpoints.GetAll}`, { params })

  readNotification = (params: number): Promise<IResponse<Notification>> => this.patch(`${params}${Endpoints.Read}`)

  getNotification = (params: number): Promise<IResponse<Notification>> => this.get(`/${params}`)
}

export const notificationsApi = new NotificationsApi(config)
