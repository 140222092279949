import { makeAutoObservable } from 'mobx'
import { Notification, NotificationResponse, notificationsApi } from '@/api/notifications'
import { addAxiosErrorNotification } from '@/components/Notifications'
import { NotificationParams } from './types'

class NotificationsStore {
  notifications: NotificationResponse | null = null
  notificationLoader = false
  notificationPage = 1
  singleNotification: Notification | null = null
  singleNotificationLoader = false

  constructor() {
    makeAutoObservable(this)
  }

  setSingleNotificationLoader = (params: boolean) => {
    this.singleNotificationLoader = params
  }

  setSingleNotification = (params: Notification | null) => {
    this.singleNotification = params
  }

  setNotificationPage = (params: number) => {
    this.notificationPage = params
  }

  setNotificationLoader = (params: boolean) => {
    this.notificationLoader = params
  }

  setNotifications = (params: NotificationResponse | null) => {
    this.notifications = params
  }

  getNotification = (params: number) =>
    notificationsApi
      .getNotification(params)
      .then((res) => {
        if (res.success) {
          this.setSingleNotification(res.data)
        }
      })
      .finally(() => {
        this.setSingleNotificationLoader(false)
      })

  readNotification = (params: number) => notificationsApi.readNotification(params).catch(addAxiosErrorNotification)

  getNotifications = (params: NotificationParams) =>
    notificationsApi
      .getNotifications(params)
      .then((res) => {
        if (res.success) {
          this.setNotifications(res.data)
        }
      })
      .catch(addAxiosErrorNotification)
      .finally(() => {
        this.setNotificationLoader(false)
      })
}

export const notificationsStore = new NotificationsStore()
