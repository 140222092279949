import { styled } from '@mui/material/styles'

type TSpinnerWrapperStyled = {
  isBlur?: boolean
}

export const SpinnerWrapperStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isBlur',
})<TSpinnerWrapperStyled>(({ isBlur }) => ({
  display: 'grid',
  height: isBlur ? '100vh' : '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  bottom: 0,
  margin: 'auto',
  right: 'auto',
  width: '100%',
  ...(isBlur && { backgroundColor: 'rgba(250, 250, 250, 0.4)' }),
}))

type TSpinnerStyled = {
  size: number
}

export const SpinnerStyled = styled('div')<TSpinnerStyled>(({ size }) => ({
  width: 'fit-content',
  height: 'fit-content',
  left: `calc(50% - ${size / 2}px)`,
  position: 'relative',
  top: `calc(50% - ${size / 2}px)`,
}))
