export const ratingDictionary = {
  coverage: 'Qamrov',
  branch: 'Fillial',
  course: 'Kurs',
  level: 'Bosqich',
  myDirection: "Yo'nalishim",
  myCourse: 'Kurslar',
  myGroup: 'Guruhim',
  image: 'Rasm',
  fullName: 'Ism-familiya',
  group: 'Guruh',
  xp: 'XP',
  silver: 'Kumush',
  programming: 'Dasturlash',
  design: 'Dizayn',
  marketing: 'Marketing',
  title: 'Nomi',
  clearFilter: 'Tozalash',
  started: 'Boshlanish sanasi',
  endedDate: 'Tugash sanasi',
  intervalDate: 'Vaqt oralig`i',
  method: 'Metod',
}
