import { makeAutoObservable } from 'mobx'
import { profileApi } from '@/api/profile'
import { IChangePassword, IProfile } from '@/api/profile/types'
import { NotificationToast } from '@/components/Notifications/notification-toast'

class ProfileStore {
  isOpenProfileEditModal = false
  isOpenLoginEditModal = false
  isOpenPasswordEditModal = false
  student: IProfile | null = null
  profileImage = false
  profileImageUrl: string | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setStudent = (student: IProfile | null) => {
    this.student = student
  }

  setProfileImageUrl = (params: string | null) => {
    this.profileImageUrl = params
  }

  setProfileImage = (params: boolean) => {
    this.profileImage = params
  }

  setIsOpenProfileEditModal = (isOpen: boolean) => {
    this.isOpenProfileEditModal = isOpen
  }

  setIsOpenLoginEditModal = (isOpen: boolean) => {
    this.isOpenLoginEditModal = isOpen
  }

  setIsOpenPasswordEditModal = (isOpen: boolean) => {
    this.isOpenPasswordEditModal = isOpen
  }

  getProfile = () =>
    profileApi
      .getProfile()
      .then((res) => {
        if (res.success) {
          this.setStudent(res.data)
        }
      })
      .catch(NotificationToast)

  changeProfilePassword = (params: IChangePassword) => profileApi.changeProfilePassword(params)

  reset() {
    this.isOpenProfileEditModal = false
    this.isOpenLoginEditModal = false
    this.isOpenPasswordEditModal = false
  }
}

export const profileStore = new ProfileStore()
