export const groupDictionary = {
  finished: 'Tugagan',
  groupName: 'Guruh nomi',
  direction: "Yo'nalishi",
  teacher: "O'qituvchi",
  teachers: "O'qituvchi(lar)",
  startDate: 'Boshlangan sana',
  finishedDate: 'Tugagan sana',
  chapter: 'Mavzular',
  video: 'Video',
  homework: 'Uyga vazifa',
  deadline: 'tugash vaqti',
  role: 'Roli',
  lessonTime: 'Dars vaqti',
  daysOfLessons: 'Dars kunlari',
  lessonDate: 'Dars sanasi',
  dontHaveVideos: 'Uyga vazifa va video mavjud emas',
  hasNotPermission: 'Bu o’qituvchi darsda tizim bilan ishlamaydi',
  activeSchedule: 'Faol',
  accepted: 'Qabul qilingan',
  rejected: 'Qaytarilgan',
  waiting: 'Kutayotgan',
  uncompleted: 'Bajarilmagan',
  notGiven: 'Berilmagan',
  videoSingle: '-video:',
  didNotStudy: 'Siz bu guruhda o’qimagansiz',
  exam: 'Imtihon vazifasi',
  didNotStudyThatMoment: 'Bu dars vaqtida guruhda o’qimagansiz',
}
