import { Endpoints } from '@/api/endpoints'
import { INetworkConfig, Instance } from '@/api/instance'
import { IResponse } from '../types'
import { IChangePassword, IProfile, IProfileUpdateParams, ISuccessResponse, IUploadImage } from './types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class Profile extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getProfile = (): Promise<IResponse<IProfile>> => this.get(Endpoints.Profile)

  updateProfile = (params: IProfileUpdateParams): Promise<IResponse<ISuccessResponse>> =>
    this.put(Endpoints.Profile, params)

  changeLogin = (newLogin: string): Promise<IResponse<IProfile>> =>
    this.post(Endpoints.ProfileChangeLogin, { newLogin })

  changeProfilePassword = (params: IChangePassword): Promise<IResponse<IProfile>> =>
    this.post(Endpoints.ProfileChangePassword, params)

  uploadProfile = (params: FormData): Promise<IResponse<IUploadImage[]>> =>
    this.post(Endpoints.UploadImageProfile, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
}

export const profileApi = new Profile(config)
