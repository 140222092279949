import 'dayjs/locale/en'
import 'react-toastify/dist/ReactToastify.css'

import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import GlobalStyles from '@mui/material/GlobalStyles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { onMessage } from '@firebase/messaging'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import { addNotification, Loading, NotificationContainer } from '@/components'
import { DEFAULT_LOCALE, months, weekDays, weekDaysShort } from '@/constants'
import { messaging } from '@/helpers/firebaseConfig'
import { globalStyles } from '@/styles'
import { Router } from './router'
import { useBootstrap } from './useBootstrap'

dayjs.extend(updateLocale)
dayjs.locale(DEFAULT_LOCALE)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tashkent')

dayjs.updateLocale('en', {
  weekStart: 1,
  months,
  weekdays: weekDays,
  weekdaysShort: weekDaysShort,
})

export const App: FC = observer(() => {
  const [isInitiated] = useBootstrap()

  onMessage(messaging, (payload) => {
    addNotification(payload?.notification?.title || '', {
      type: 'info',
    })
  })

  if (isInitiated) {
    return <Loading />
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <GlobalStyles styles={globalStyles} />

      <Router />

      <NotificationContainer />
    </LocalizationProvider>
  )
})
