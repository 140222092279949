import { makeAutoObservable } from 'mobx'
import { ratingApi } from '@/api/rating'
import {
  IRatingCategory,
  IRatingLevelByCategory,
  IRatingParams,
  IRatingStatistic,
  IRatingStatisticCourse,
  IRatingStatisticGroups,
  RatingCategoryId,
  RatingOrderBy,
  TBranch,
} from '@/api/rating/types'
import { addAxiosErrorNotification } from '@/components/Notifications'
import { IGetCourseRequest, IRatingLevelSort } from './types'

class RatingStore {
  page = 1
  perPage = 10
  total = 0
  activeCategoryId: number | undefined = 0
  groupId: number | null = null
  courseId: number | null = null
  courses: IRatingStatisticCourse[] = []
  categories: IRatingCategory[] = []
  categoryId: RatingCategoryId | null | undefined = null
  activeStudentRating: IRatingStatistic | undefined = undefined
  studentsRating: IRatingStatistic[] = []
  sortBy: IRatingLevelSort = IRatingLevelSort.XP
  orderBy: RatingOrderBy = RatingOrderBy.DESC
  isOpenGroupModal = false
  selectStudentGroup: IRatingStatisticGroups[] = []
  filterLevels: IRatingLevelByCategory[] = []
  level: number | null = null
  startDate: string | null | Date = null
  endDate: string | null | Date = null
  branches: TBranch[] | null = null
  selectedBranch: number | null = null
  method: number | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setMethod = (params: null | number) => {
    this.method = params
  }

  setSelectedBranch = (params: number | null) => {
    this.selectedBranch = params
  }

  getCategories = () =>
    ratingApi
      .getRatingCategories()
      .then((res) => {
        if (res?.success && res?.data) {
          this.setCategories(res.data.categories)
        }
      })
      .catch(addAxiosErrorNotification)

  getRating = (params: IRatingParams) =>
    ratingApi
      .getRating(params)
      .then((res) => {
        if (res?.success && res?.data) {
          this.total = res.data.total
          this.activeStudentRating = res.data?.studentStatisticsData?.studentStatistic
          this.setStudentsRating(res.data?.studentStatisticsData?.statistics)
        }
      })
      .catch(addAxiosErrorNotification)

  getBranches = () =>
    ratingApi
      .getBranches()
      .then((res) => {
        if (res.success) {
          this.branches = res?.data?.offices
        }
      })
      .catch(addAxiosErrorNotification)

  getLevelsByCategory = () =>
    ratingApi
      .getLevelByCategory()
      .then((res) => {
        if (res?.success && res?.data) {
          this.setFilterevels(res.data?.levels)
        }
      })
      .catch(addAxiosErrorNotification)

  getCourses = (params?: IGetCourseRequest) =>
    ratingApi
      .getCourses(params)
      .then((res) => {
        if (res?.success && res?.data) {
          this.setCourses(res.data?.courses)
        }
      })
      .catch(addAxiosErrorNotification)

  setActiveCategoryId = (id: number | undefined) => {
    this.activeCategoryId = id
  }

  setCategories = async (categories: IRatingCategory[]) => {
    this.categories = categories
  }

  setStudentsRating = (ratings: IRatingStatistic[]) => {
    this.studentsRating = ratings
  }

  setCategoryId = (id: RatingCategoryId | undefined) => {
    this.categoryId = id
  }

  setCourses = (courses: IRatingStatisticCourse[]) => {
    this.courses = courses
  }

  setSortBy = (sortBy: IRatingLevelSort) => {
    this.sortBy = sortBy
  }

  setPage = (page: number) => {
    this.page = page
  }

  setPerPage = (perPage: number) => {
    this.perPage = perPage
  }

  setOrderBy = (orderBy: RatingOrderBy) => {
    this.orderBy = orderBy
  }

  setIsOpenGroupModal = (isOpen: boolean) => {
    this.isOpenGroupModal = isOpen
  }

  setSelectStudentGroup = (group: IRatingStatisticGroups[]) => {
    this.selectStudentGroup = group
  }

  setFilterevels = (levels: IRatingLevelByCategory[]) => {
    this.filterLevels = levels
  }

  setLevel = (level: number | null) => {
    this.level = level
  }

  setGroupId = (groupId: number | null) => {
    this.groupId = groupId
  }

  setCourseId = (courseId: number | null) => {
    this.courseId = courseId
  }

  setFrom = (from: string | null | Date) => {
    this.startDate = from
  }

  setTo = (to: string | null | Date) => {
    this.endDate = to
  }

  reset = () => {
    this.page = 1
    this.perPage = 10
  }
}

export const ratingStore = new RatingStore()
